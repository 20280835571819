import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	AuthService,
	AwsS3Service,
	ConfigService,
	FileService,
	NewOrderService,
	OrderListingService,
	PricingService,
	ProductService,
	SettingsService,
	StaticTextService,
} from 'app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { CONSTANTS } from 'app/app.constants';
import { ErrorDialogComponent } from 'app/dialog/error-dialog/error-dialog.component';
import { DialogService } from '../../module/dialog/bootstrap-modal.module';
import { ConfirmComponent } from 'app/dialog/confirm/confirm.component';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorHandler } from 'app/error-handler/error.handler';
import * as stripe from 'app/services/stripe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { ONLY_STRING_REGEX } from '@utils/regex';
import { theme } from '@styled';
import {
	isNotEmptyObj,
	addressToObj,
	Dict,
	get,
	scrollToFocusElements,
	to,
	__DEV__,
	isNil,
	isFn,
	isString,
	isNull,
	isObj,
} from '@utils';
import { UploadItem } from '@common/uploads/upload.component';

@Component({
	selector: 'app-order-fly',
	templateUrl: './order-fly.component.html',
	styleUrls: ['./order-fly.component.scss'],
})
export class OrderFlyComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('alert', { static: true }) alertRef: any;

	@ViewChild('fileInput', { static: true }) fileInput: ElementRef;

	@ViewChild('cardInfo') cardInfo: ElementRef;

	@ViewChild('addressRef') addressRef: any;

	@ViewChild('paymentMethodRef') paymentMethodRef: any;

	@ViewChild('docsRef') uploadRef: any;

	card: any;
	cardHandler = this.onChange.bind(this);
	cardError: string;

	isLoading: boolean = true;

	isValid: boolean = false;

	orderDetails: any = {};

	docs = [];
	products = [];
	newDocs = [];
	selectedState = '';
	selectedCounty = '';
	selectedProduct: any;
	selectedProductDescription: any;
	selectedProductName: any;
	productDetails: any = {};
	productEstimatedTime = '';
	ordDetails: any = {};
	orderNotes: any = {};
	orderIns: any = {};
	constants = CONSTANTS;
	unsubscribe = new Subject();
	states: any[];
	prepayment: number = 0;

	stripeCustomer: stripe.types.StripeCustomer = null;
	hidePaymentForm = true;

	get error(): string {
		return this._error;
	}
	set error(val: string | null) {
		if (isString(val)) {
			this._error = val;
		}
		this.cdr.detectChanges();
	}
	_error: string = '';

	constructor(
		protected cdr: ChangeDetectorRef,
		private orderService: OrderListingService,
		private route: ActivatedRoute,
		public text: StaticTextService,
		private router: Router,
		private config: ConfigService,
		private s3Service: AwsS3Service,
		private matDialog:MatDialog,
		private auth: AuthService,
		private fileService: FileService,
		private newOrder: NewOrderService,
		private location: Location,
		private dialog: MatDialog,
		private pricingService: PricingService,
		private fb: UntypedFormBuilder,
		private errorHandler: ErrorHandler,
		private stripeService: stripe.service,
		private cd: ChangeDetectorRef,
		private productService: ProductService,
		private snackbar: MatSnackBar,
		private settingsService: SettingsService
	) {
		if (__DEV__) {
			window['orderfly'] = this;
		}
	}

	private static removeBillingPrefix(message: string): string {
		return message.substring('Billing '.length);
	}

	private static extractFilename(filePath) {
		const parts = filePath.split('/');
		return parts[parts.length - 1];
	}

	private static validateFilenames(event) {
		let flag = 0;
		for (let i = 0; i < event.target.files.length; i++) {
			const filename = event.target.files[i].name;
			if (filename.indexOf(',') > -1) flag++;
		}
		return flag == 0;
	}

	ngOnInit() {
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		this.orderService.getOrderProducts().subscribe(products => {
			this.products = products;
			let updateSearchIndex = this.products.findIndex(x => x.Product_ID == 5);
			this.selectedProduct = this.products[updateSearchIndex].Product_ID;
			this.selectedProductDescription = this.products[updateSearchIndex].Product_Description
			this.selectedProductName = this.products[updateSearchIndex].Product_Name
			this.getOrderProducts(this.route.snapshot.params['orderId']);
			this.fetchUploadProgress();
			this.getProductEstimatedTime();
		});

		this.getStateList();
		this.getPrepaymentStatus();
		this.getStripeCustomer();
	}

	ngAfterViewInit() {
		// Theming
		document.body.style.background = theme.colors.bg.level1;
	}

	ngOnDestroy() {
		// Remove theming
		document.body.style.background = '';

		this.unsubscribe.next(null);
		this.unsubscribe.complete();
		this.stripeService.destroyCard(this.card, this.cardHandler);
	}

	getBillingAddress = async () => {
		if (this.paymentMethodRef && !this.paymentMethodRef.isLoading) {
			// Preload UI with default user billing address.
			const [err, address] = await to(this.auth.getDefaultAddress());
			if (!err) this.addressRef.patchValue(address);
			// Enable UI
			this.isValid =
				get(this, 'addressRef.valid') && get(this, 'paymentMethodRef.isValid');
		}
	};

	handleValid = () => {
		if (this.paymentMethodRef.isValid && this.addressRef.valid) {
			this.isValid = true;
			this.cdr.detectChanges();
		}
	};

	handleInvalid = () => {
		if (!this.paymentMethodRef.isValid || !this.addressRef.valid) {
			this.isValid = false;
			this.cdr.detectChanges();
		}
	};

	handleCardChange = (card: Dict) => {
		this.isValid = this.addressRef.valid && get(this, 'paymentMethod.isValid');
		this.cdr.detectChanges();
	};

	handleCardSelect = (card: Dict) => {
		this.addressRef.patchValue(addressToObj(card));
		this.cdr.detectChanges();
	};

	handleCardLoaded = async (card: Dict) => {
		if (isObj(card)) {
			this.addressRef.patchValue(addressToObj(card));
		} else {
			const [err, address] = await to(this.auth.getDefaultAddress());
			if (!err) this.addressRef.patchValue(address);
		}
		this.isLoading = false;
		return this.cdr.detectChanges();
	};

	toggle() {
		this.hidePaymentForm = !this.hidePaymentForm;
		setTimeout(() => scrollTo(0, document.body.scrollHeight), 301);
	}

	public get creditCards() {
		if (this.stripeCustomer) {
			return this.stripeCustomer.sources.data;
		}
		return null;
	}

	public goBack() {
		this.location.back();
	}

	public getTotal() {
		if (!isNaN(this.productDetails.Cost_Amount + this.productDetails.Cost_Tax_Amount))
			return this.productDetails.Cost_Amount + this.productDetails.Cost_Tax_Amount;
		else return 0;
	}

	public buildAddress(model) {
		let address = '';
		if (model.Property_Address_1) address += model.Property_Address_1;
		if (model.Property_Address_2) {
			if (address) address += '\n';
			address += model.Property_Address_2;
		}
		if (model.Property_City) {
			if (address) address += '\n';
			address += model.Property_City;
		}
		if (model.Property_State_Abbr) {
			if (address) address += ', ';
			address += model.Property_State_Abbr;
		}
		if (model.Property_ZipCode) {
			if (address) address += ' ';
			address += model.Property_ZipCode;
		}
		return address;
	}

	public openFileSelector(event) {
		this.fileInput.nativeElement.value = null;
		this.fileInput.nativeElement.click();
	}

	public uploadDocument(event) {
		if (OrderFlyComponent.validateFilenames(event)) {
			const fileUploads = [];
			if (this.checkSize(event.target.files)) {
				for (let i = 0; i < event.target.files.length; i++) {
					const uid = UUID.UUID();
					this.newDocs.push({
						docId: uid,
						name: event.target.files[i].name,
						completed: 0,
					});
					fileUploads.push(this.uploadToS3AndUpdatePath(event.target.files[i], uid));
				}
				Promise.all(fileUploads)
					.then(files => {
						this.removeCompletedDocs(files);
						var succesFiles = files.filter(file => file !== undefined);
						if (succesFiles.length > 0)
							this.orderDetails.orderDocId = this.orderDetails.orderDocId.concat(
								succesFiles,
							);
					})
					.catch(() => {
						this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
					});

				this.cdr.detectChanges();
				if (this.uploadRef) {
					const scrollIntoView = get(this, 'uploadRef.nativeElement.scrollIntoView');
					if (scrollIntoView) scrollIntoView({ block: 'center' });
				}
			} else {
				this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
			}
		} else {
			this.openErrorPopup(CONSTANTS.apiErrors.validateFile);
		}
	}

	public deleteNewDocs(documents, index) {
		// this.dialogService
		// 	.addDialog(ConfirmComponent, {
		// 		title: 'Delete Document',
		// 		message: 'Are you sure want to delete ' + documents.Document_Original_Name + ' ?',
		// 	})
		// 	.subscribe(res => {
		// 		if (res) {
		// 			this.orderDetails.orderDocId.splice(index, 1);
		// 		} else {
		// 		}
		// 	});
			let dialogRef = this.matDialog.open(ConfirmComponent, {
				data: {
					title: 'Delete Document',
					message: 'Are you sure want to delete ' + documents.Document_Original_Name + ' ?',
				}
			});
	
			dialogRef.afterClosed().subscribe((res) => {
				if (res) {
					this.orderDetails.orderDocId.splice(index, 1);
				}
			})
	}

	public deleteClientDocs(documents, index) {
		let dialogRef = this.matDialog.open(ConfirmComponent, {
			data: {
				title: 'Delete Document',
				message: 'Are you sure want to delete ' + documents.Document_Original_Name + ' ?',
			}
		});

		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.orderDetails.clientDoc.splice(index, 1);
			}
		})
	}

	public checkout(useSavedCard: boolean, card: any, address: Dict) {
		// ⚠️ Both the address form and the paymentMethod inputs have to be valid
		// for the checkout button to be enabled - this check will never go to the second part.
		if (this.addressRef.valid) {
			this.newOrder.billingData = address.value;

			if (useSavedCard) {
				this.newOrder.stripeCustomerId = this.stripeCustomer.id;
				this.newOrder.stripeCardId = card.id;
				this.createOrder();
			} else {
				this.stripeService
					.createStripeTokens(card, this.serializedBillingData())
					.then(() => this.createOrder())
					.catch(err => (this.cardError = err.message));
			}
		} else {
			this.snackbar.open('The order form is not valid', '', {
				horizontalPosition: 'end',
				verticalPosition: 'bottom',
				panelClass: 'danger-snackbar',
				duration: 2000,
			});
			// Object.keys(this.billingForm.controls).forEach((control: string) => {
			// 	this.billingForm.get(control).markAsTouched();
			// });
		}
	}

	public clearError = () => {
		this.error = '';
		return this.cdr.detectChanges();
	};

	/** Used to get error of the field given. */
	public getError(field: string): string {
		const errorMessage = this.errorHandler.getError(this.addressRef.fc, field);
		return OrderFlyComponent.removeBillingPrefix(errorMessage);
	}

	public getCardImageByBrand(brand: string) {
		return this.stripeService.getCardImageByBrand(brand);
	}

	private initiateStripeCardElement() {
		// const stripe = Stripe(environment.stripePublishKey)
		// const elements = stripe.elements();
		this.card = elements.create('card', {
			style: this.stripeService.baseStyle,
			hidePostalCode: true,
		});
		this.card.mount(this.cardInfo.nativeElement);
		this.card.addEventListener('change', this.cardHandler);
	}

	private onChange({ error }) {
		if (error) this.cardError = error.message;
		else this.cardError = null;

		this.cd.detectChanges();
	}

	private getProductEstimatedTime() {
		this.productService
			.getProductEstimatedTime(this.selectedProduct)
			.subscribe(res => (this.productEstimatedTime = res.Estimated_Time));
	}

	private serializedBillingData() {
		return this.stripeService.getSerializedBillingAddress(this.addressRef.value);
	}

	private getOrdDocuments(ordId) {
		this.orderService.getOrderDocuents(ordId).subscribe(docs => {
			this.orderDetails.clientDoc = docs.filter(
				clientDoc => clientDoc.Document_Type == 2,
			);
			this.orderDetails.adminDoc = docs.filter(clientDoc => clientDoc.Document_Type == 1);
		});
	}

	private getStateList() {
		this.pricingService.getStateList().subscribe(states => {
			if (states) {
				const tempStates = [];
				states.forEach(state =>
					tempStates.push({ State_Short_Name: state[0].toUpperCase() }),
				);
				this.states = tempStates;
			}
		});
	}

	/** Get prepayment status from server and attach it 'this.prepayment' */
	private getPrepaymentStatus() {
		this.newOrder
			.reqUserPrepaymentStatus()
			.subscribe((response: { prepayment: number }) => {
				this.prepayment = response.prepayment;
			});
	}

	private getOrderProducts(orderId) {
		this.orderService.getOrderDetails(orderId).subscribe(result => {
			this.orderDetails = result;
			this.orderDetails.orderDocId = [];
			this.getOrdDocuments(orderId);
			this.getProductPricing();
			this.getOrderNotes(orderId);
		});
	}

	private getProductPricing() {
		if (
			this.orderDetails.Property_State_Abbr &&
			this.orderDetails.Property_County &&
			this.selectedProduct
		)
			this.newOrder
				.reqProductPricing(
					this.orderDetails.Property_State_Abbr,
					this.orderDetails.Property_County,
					this.selectedProduct,
				)
				.subscribe(productPrice => {
					if (productPrice) {
						this.productDetails = productPrice;
						if (this.productDetails.Cost_Tax_Amount == null)
							this.productDetails.Cost_Tax_Amount = 0;
					} else {
						this.productDetails.Cost_Amount = 0;
						this.productDetails.Cost_Tax_Amount = 0;
						this.productDetails.Cost_Min_Tat = null;
						this.productDetails.Cost_Max_Tat = null;
					}

					this.getBillingAddress();
				});
	}

	private getOrderNotes(orderId) {
		this.orderService.getOrderNotes(orderId).subscribe(notes => {
			notes.forEach(note => {
				if (note.Instruction_Type == CONSTANTS.instructionType.orderNotes) {
					this.orderNotes = note;
					this.orderDetails.Property_Order_Notes = this.orderNotes.Instruction_Text;
				}
				if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) {
					this.orderIns = note;
					this.orderDetails.Order_Instructions = this.orderIns.Instruction_Text;
				}
			});
		});
	}

	private removeCompletedDocs(files) {
		for (let i = 0, len = files.length; i < len; i++) {
			for (let j = 0, len2 = this.newDocs.length; j < len2; j++) {
				if (files[i] && files[i].docId === this.newDocs[j].docId) {
					this.newDocs.splice(j, 1);
					len2 = this.newDocs.length;
				}
			}
		}
	}

	private checkSize(files) {
		const largeFiles = Array.prototype.filter.call(
			files,
			file => file.size > this.config.getMaxFileSize() * 1024 * 1024,
		);
		return largeFiles.length <= 0;
	}

	private uploadToS3AndUpdatePath(file, propertyId) {
		return this.s3Service
			.onManagedUpload(file, '0')
			.then(data => {
				return this.s3Service
					.updateDocumentPath(
						'0',
						propertyId,
						file,
						data.Key,
						OrderFlyComponent.extractFilename(data.Key),
					)
					.then(doc => {
						doc['docId'] = propertyId;
						doc['completed'] = 100;
						return doc;
					})
					.catch(err => {
						throw err;
					});
			})
			.catch(() => {
				this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
			});
	}

	private openErrorPopup(msg) {
		let dialogRef=this.matDialog.open(ErrorDialogComponent,{
			data:{
				message: msg 
			}
		});

		
		// this.dialogService.addDialog(ErrorDialogComponent, { message: msg }).subscribe();
	}

	private updateOrderDocs(data) {
		const docUpdate = [];
		this.ordDetails.orderDocId.forEach(docId => {
			docUpdate.push(
				this.newOrder.updateOrderDocument(
					data.Order_ID,
					data.Property_ID,
					docId.Order_Document_ID,
				),
			);
		});
		observableForkJoin(docUpdate).subscribe();
	}

	private createOrder() {
		this.getOrderData();
		let orderDoc = [];
		orderDoc = orderDoc.concat(this.orderDetails.adminDoc);
		orderDoc = orderDoc.concat(this.orderDetails.clientDoc);
		this.auth.checkIfQualaiUser(this.auth.getUserId())
		.subscribe((data) => {
			this.ordDetails.Qualia_Fee_Add= data.status ? 1: 0
			if(this.auth.getUserOrgId()){
				let clientType = null;
				this.auth.getOrgDetails(this.auth.getUserOrgId())
				.subscribe((orgDetails) => {
					if(orgDetails) {
						if(orgDetails.Organization_Type == 1)  clientType = 4;
						else if(orgDetails.Organization_Type == 4) clientType = 3;
						else if(orgDetails.Organization_Type == 8) clientType = 2;
						else clientType = 7;
					}
					else clientType = 7;
					this.ordDetails.Order_Source_Type_ID= clientType;
					this.processRequest(orderDoc);
				})
			}
			else {
				this.settingsService.getNotificationFlagValue(7)
				.subscribe((clientTypeFlag) => {
					let clientType = null;
					if (clientTypeFlag) {
						if (clientTypeFlag.Flag_Value == 1) clientType = 4;
						else if (clientTypeFlag.Flag_Value == 4) clientType = 3;
						else if (clientTypeFlag.Flag_Value == 8) clientType = 2;
						else clientType = 7;					
					}
					else clientType = 7;
					this.ordDetails.Order_Source_Type_ID= clientType;
					this.processRequest(orderDoc);
				})
			}
			
		})		
	}

	processRequest(orderDoc){		
		this.newOrder.updateOrder(orderDoc, this.ordDetails).subscribe(
			result => {
				this.clearError();
				if (this.ordDetails.orderDocId) {
					if (this.ordDetails.orderDocId.length > 0) this.updateOrderDocs(result);
				}
				this.router.navigate(['/orders/' + result.Order_ID + '/view']).then();
			},
			error => {
				if (error.msg) {
					this.error = error.msg;
				} else {
					this.error =
						'An unexpected error has occurred. You are not charged. Please try to place your order again';
				}
				this.cdr.detectChanges();
			},
		);
	}

	private getOrderData() {
		this.ordDetails = {
			Product_Description:this.selectedProductDescription,
			Product_Name:this.selectedProductName,
			Property_State_Abbr: this.orderDetails.Property_State_Abbr,
			selectedProduct: this.selectedProduct,
			Property_Order_Number: this.orderDetails.File_ID,
			Property_Latitude: this.orderDetails.Property_Latitude,
			Property_Longitude: this.orderDetails.Property_Longitude,
			Property_Products: [this.selectedProduct],
			Property_Address_1: this.orderDetails.Property_Address_1,
			Property_City: this.orderDetails.Property_City,
			Property_County: this.orderDetails.Property_County,
			Property_ZipCode: this.orderDetails.Property_ZipCode,
			orderDocId: this.orderDetails.orderDocId,
			Cost_Amount: this.orderDetails.Order_Final_Price,
			Order_Parcel: this.orderDetails.Order_Parcel,
			Order_Loan: this.orderDetails.Order_Loan,
			Order_Buyer: this.orderDetails.Order_Buyer,
			Order_Co_Buyer: this.orderDetails.Order_Co_Buyer,
			Property_Order_Notes: this.orderDetails.Property_Order_Notes,
			Order_Instructions: this.orderDetails.Order_Instructions,
			Order_Book: this.orderDetails.Order_Book,
			Order_Loadn: this.orderDetails.Order_Loan,
			Order_Seller: this.orderDetails.Order_Seller,
			Order_Co_Seller: this.orderDetails.Order_Co_Seller,
			Order_Mortgage_Amount: this.orderDetails.Order_Mortgage_Amount,
			Order_Mortgage_Date: this.orderDetails.Order_Mortgage_Date,
			Order_Page: this.orderDetails.Order_Page,
			Property_First_Name: this.orderDetails.Property_First_Name,
			Order_Status: CONSTANTS.orderStatus.received,
			Order_Final_Price: this.productDetails.Cost_Amount,
			Order_Final_Price_Tax: this.productDetails.Cost_Tax_Amount,
			Order_Min_Tat: this.productDetails.Cost_Min_Tat,
			Order_Max_Tat: this.productDetails.Cost_Max_Tat,
			Order_Custom_Price: null,
			// Order_Source_Type_ID: 7,
			Order_Mode_Type_ID: 2,
			Organization_ID : this.orderDetails.Organization_ID,
			Parent_Order_ID:  this.orderDetails.Order_ID,
			Order_Subdivision: this.orderDetails.Order_Subdivision,
		};
	}

	private fetchUploadProgress() {
		this.s3Service.progress.subscribe(res => {
			const matchedIndex = this.newDocs.findIndex(doc => doc.name == res.name);
			if (this.newDocs[matchedIndex]) {
				this.newDocs[matchedIndex].completed =
					Math.round((res.progress.loaded / res.progress.total) * 100 * 100) / 100;
				this.newDocs[matchedIndex].completed = Math.round(
					this.newDocs[matchedIndex].completed,
				);
			}
		});
	}

	private getStripeCustomer() {
		this.newOrder.reqStripeCustomer().subscribe(customer => {
			this.stripeCustomer = customer;
			if (customer) {
				this.hidePaymentForm = customer.sources.data.length > 0;
			} else {
				this.hidePaymentForm = false;
			}
		});
	}

	protected openDialogError = (message: string) =>
		this.matDialog.open(ErrorDialogComponent, { data:{message:message} });
}
