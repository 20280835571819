import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, catchError, throwError as observableThrowError } from 'rxjs';
import { AuthService } from '../../services/authentication/auth.service';
import { ConfigService } from '../../services/config/config.service';

interface ApprovalStatus {
	isQuestionnaireComplete: boolean;
	isCustomerSuccessCallScheduled: boolean;
	isClientApproved: boolean;
	isEULAAccepted:boolean;
	questionnaireQueryString:string;
	scheduleCallQueryString:string;
}

@Injectable({
	providedIn: 'root',
})
export class PendingApprovalService {
	constructor(
		private http: HttpClient,
		private auth: AuthService,
		private config: ConfigService,
	) {}

	fetchApprovalStatus(userId:String): Observable<ApprovalStatus> {
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.auth.getToken() }),
		};
		let url = this.config.getBasePath() + '/v2/client/pendingStatus';
		const data = { userId: userId };
    console.log({data})
		return this.http.post(url, data, httpOptions).pipe(
			map((response: any) => {
				console.log({ response });
				return response;
			}),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}
}
